import logo from '../images/logo_long.png';
import '../pages/HomePage.css';
import { Menu } from '../components/Menu';
import React from 'react';
import { Link } from 'react-router-dom';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Header({ menu, hideMenu }) {
  var menuSlider;
  if (hideMenu == false) {
    menuSlider =
      <div className='MenuWrapper'>
        <Menu menu={menu}></Menu>
      </div>
  }
  return (<header className='StickyStyle'>
    <div className="AppHeaderWrapper">
      <div className="App-header">
        

        <Link className='AppHeaderLeft' to="/" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth', })}>
          <img src={logo} className="App-logo" alt="logo" style={{height:50, display:'flex', justifyContent:'center', alignContent:'center'}}/>
        </Link>
        <div className='AppHeaderRight'>
          {/*<a href="https://www.facebook.com/pages/Eli's%20D%C3%B6ner/622389974846130/" target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faFacebookF} fontSize={25} color={"#e7e7e7"} style={{ padding: 10 }} /></a>*/}
          <a href="https://www.instagram.com/crystal_liezen/" target="_blank" rel="noopener noreferrer"  ><FontAwesomeIcon icon={faInstagram} fontSize={25} color={"#e7e7e7"} style={{ padding: 10 }} /></a>
          <a href="https://goo.gl/maps/DRpf4GGK6YRCGTBN7" target="_blank" rel="noopener noreferrer"  ><FontAwesomeIcon icon={faLocationDot} fontSize={25} color={"#e7e7e7"} style={{ padding: 10 }} /></a>
        </div>
        {/*<div className='AppHeaderRight'>
          <a href="tel:+43361224179" target="_blank" rel="noopener noreferrer" style={{ color: "#e7e7e7", textDecoration: "none", paddingRight: 10 }}><FontAwesomeIcon icon={faPhone} fontSize={10} color={"#e7e7e7"} style={{ padding: 3 }} />03612 24 179</a>
</div>*/}
      </div>
    </div>
    {menuSlider}
  </header>)
}

export default Header;