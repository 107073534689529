import './App.css';
import React, { useEffect, useState, } from 'react';
import HomePage from './pages/HomePage';
import Impressum from './pages/Impressum';
import { BrowserRouter, Routes, Route, } from 'react-router-dom';
import Allergenics from './pages/Allergenics';
import "./fonts/PTSans-Regular.ttf";
import "./fonts/PTSans-Bold.ttf";
import "./fonts/Conthrax.ttf";
import "./fonts/Montserrat-Regular.ttf";

function App() {
  const [menu, setMenu] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/data.json');
      const json = await response.json();
      setMenu(json.menu);
    };

    fetchData();
    setLoading(false);
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<HomePage menu={menu} loading={loading} />} />
        <Route exact path="/impressum" element={<Impressum menu={menu} />} />
        <Route exact path="/allergene" element={<Allergenics menu={menu} />} />

        <Route path="*" element={<HomePage menu={menu} loading={loading} />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
