import './HomePage.css';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ToTopButton from "../components/ToTopButton.js";
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../images/logo_long.png';
import allergenics from '../images/allergenics.jpg';
import Footer from '../components/Footer';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import Header from '../components/Header';

function Allergenics({ data }) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return (
    <div className="App">
      <Header data={data} hideMenu={true} />
      <body>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}><img src={allergenics} className="allergenicImageStyle" alt="Allergene" /></div>
        
          <ToTopButton></ToTopButton>
        </div>
      </body>
      <Footer></Footer>
    </div>
  );
}

export default Allergenics;