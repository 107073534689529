import React from 'react';
import './MenuEntryCard.css';

function MenuEntryCard({ item }) {

  var price;
  var priceBottle;
  var priceShot;

  // for only single price
  if (item.price) {
    price = <div className="menuCardPrice">{item.price + ""}</div>;
  }
  // for multiple prices
  if (item.priceBottle || item.priceShot) {
    if (item.price) {
      price =
        <div className='menuCardPriceColumn'>
          <div className="menuCardPricePartySingle">{"glass"}</div>
          <div className="menuCardPrice">{item.price + ""}</div>
        </div>
    }
    if (item.priceBottle) {
      priceBottle = <div className='menuCardPriceColumn'>
        <div className="menuCardPricePartySingle">{"bottle"}</div>
        <div className="menuCardPrice">{item.priceBottle + ""}</div>
      </div>
    }

    if (item.priceShot) {
      priceShot = <div className='menuCardPriceColumn'>
        <div className="menuCardPricePartySingle">{"shot"}</div>
        <div className="menuCardPrice">{item.priceShot + ""}</div>
      </div>
    }
  }

  var shishaDescriptionArray; 
  var descriptionString = ""; 

  if(item.type == "shisha")
  {
    descriptionString = item.description.map((string, index) => (
      <div key={index}>
        {string}
        <br />
      </div>
    ))
  }
  else 
  {
    descriptionString = item.description;
  }
  

  return (
    <div className='menuCardWrapper'>
      <div className="menuCardEntry">
        <div className='menuCardEntryColumn'>
          <div className="menuCardTitle">{item.title}  </div>
          <div className="menuCardSubtitle"> {descriptionString}</div>
          <div className="menuCardAllergenic">{item.allergenic}</div>
        </div>
        {priceShot}
        {price}
        {priceBottle}
      </div>
    </div>
  );
}

export default MenuEntryCard;